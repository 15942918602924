var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var cart = require('cart');

/**
 * Update cart icon
 *
 * @param selector
 */
function update(selector) {
    var amount = 0;
    var amountBox = $(selector);

    var orders = cart.get();
    for (var i in orders) {
        var items = orders[i].items;
        amount += items.length;
    }

    if (amount == 0) {
        amountBox.css('display', 'none');
    } else {
        amountBox.css('display', 'inline-block');
    }

    amountBox.find('span').text(amount);

    if (amount > 9) {
        amountBox.css('padding', '1px 0 0 3px');
    }
}

module.exports = {
    'update': update
};