var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var offcanvas = require('./offcanvas.js');
var cart = require('cart');
var cartIcon = require('cart-icon');
var routing = (typeof window !== "undefined" ? window['Routing'] : typeof global !== "undefined" ? global['Routing'] : null);

$(document).ready(function() {

    // Start foundation
    $(document).foundation();

    // Start offcanvas
    offcanvas();

    // Start select2
    $('select.select2').each(function (i, element) {
        // Select2's data- attributes are broken. Implement them manually.
        $(element).select2({
            width: $(element).attr('data-width') || '100%',
            dropdownAutoWidth: $(element).attr('data-dropdown-auto-width') == 'true',
        });
    });

    // Date picker
    $('input.datepicker').flatpickr({
        allowInput: true,
        dateFormat: 'd-m-Y',
    });

    // Organisation picker
    $('form[name="_organisation"]').on('change', 'select', function () {
        $(this).closest('form').submit();
    });

    // Update cart amount
    cartIcon.update('.cart-amount');

    // Empty shopping cart while logging out
    $('.logout').on('click', function () {
        cart.clear();
    });

    // Grid select-all
    $('.check-all').on('change', function () {
        $(this).closest('table').find('tbody input[type=checkbox]').prop('checked', $(this).prop('checked'));
    });

    $('table').on('change', 'tbody input[type=checkbox]', function () {
        if ($(this).prop('checked') == false) {
            $(this).closest('table').find('input.check-all').prop('checked', false);
        }

        if ($(this).closest('tbody').find('input[type=checkbox]').not(':checked').length == 0) {
            $(this).closest('table').find('input.check-all').prop('checked', true);
        }
    });

    $('table').each(function(i, table) {
        $(table).find('input.check-all').prop('checked',
            $(table).find('tbody input[type=checkbox]').not(':checked').length == 0
        );
    });

    // Pagination
    $('.pagination form input[type="number"]').on('change', function () {
        var min = parseInt($(this).attr('min'));
        var max = parseInt($(this).attr('max'));
        var val = parseInt($(this).val());

        if (min <= val && val <= max) {
            $(this).closest('form').submit();
        }
    });

    // Collapsable list
    $('.collapsable-list').on('click', 'i.fa-li', function () {
        if ($(this).parent().is('.collapsed')) {
            $(this).parent().removeClass('collapsed');
            $(this).removeClass('fa-caret-right').addClass('fa-caret-down');
        } else {
            $(this).parent().addClass('collapsed');
            $(this).removeClass('fa-caret-down').addClass('fa-caret-right');
        }
    });
});
