var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function () {

    var $wrapper = $('.offcanvas-wrap');

    $wrapper
        .on('click', '[data-offcanvas-toggle]', function (event) {
            event.preventDefault();

            $menu = $($(this).attr('href'));
            $menu.toggleClass('expanded');
            $('.offcanvas-exit').toggle();

            $wrapper.data('offcanvas', $menu);
        })
        .on('click', '.offcanvas-exit', function (event) {
            event.preventDefault();

            $wrapper.data('offcanvas').toggleClass('expanded');
            $('.offcanvas-exit').hide();

            $wrapper.data('offcanvas', null);
        });

};
