var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var routing = (typeof window !== "undefined" ? window['Routing'] : typeof global !== "undefined" ? global['Routing'] : null);
var uuid = require('uuid');
var translator = (typeof window !== "undefined" ? window['Translator'] : typeof global !== "undefined" ? global['Translator'] : null);

var storage = window.localStorage;

var storageKey = 'cart';

/**
 * Add new item to local storage
 *
 * @param action
 * @param custodian
 * @param item
 */
function addItem(id, action) {
    $.ajax({
        url: routing.generate('prezent_app_cart_item', {'id': id}),
        method: 'POST',
        dataType: 'json',
        async: false,
        success: function (item) {
            var orders = get();
            var orderData = findOrder(action, item.custodian, item.customer);

            if (orderData == null) {
                orderData = {'id': uuid.v4(), 'order': createOrder(action, item.custodian, item.customer)};
            }

            var order = orderData.order;
            order.items.push(item);

            orders[orderData.id] = order;

            set(orders);
        }
    });
}

/**
 * Get order based on the given providers
 *
 * @param action
 * @param custodianId
 * @returns {*}
 */
function findOrder(action, custodian, customer) {
    var orders = get();

    for (var id in orders) {
        var order = orders[id];

        if (order.action == action && order.custodian.id == custodian.id && order.customer.id == customer.id) {
            return {'id': id, 'order': order};
        }
    }

    return null;
}

/**
 * Create new order
 *
 * @param action
 * @param custodian
 * @param customer
 * @returns {{}}
 */
function createOrder(action, custodian, customer) {
    return {
        'action': action,
        'custodian': custodian,
        'customer': customer,
        'items': []
    };
}

/**
 * Empty local storage
 */
function clear() {
    storage.removeItem(storageKey);
}

/**
 * Get full content of the local storage
 */
function get() {
    var data = JSON.parse(storage.getItem(storageKey) || '{}');

    if (data == null) {
        data = {};
    }

    return data;
}

/**
 * Save orders into localStorage
 *
 * @param orders
 */
function set(orders) {
    storage.setItem(storageKey, JSON.stringify(orders));
}

/**
 * Remove an item from the local storage
 *
 * @param id
 */
function removeItem(id) {
    var orders = get();

    for (var i in orders) {
        var order = orders[i];

        for (var j in order.items) {
            if (order.items[j].id == id) {
                // Remove item from array
                order.items.splice(j, 1);

                // Update order in list
                orders[i] = order;
            }
        }

        // Remove complete order when no more items were found
        if (order.items.length == 0) {
            delete orders[i];
        }
    }

    set(orders);
}

/**
 * Remove order by identifier
 *
 * @param id
 */
function removeOrder(id) {
    var orders = get();

    delete orders[id];

    set(orders);
}

/**
 * Retrieve all available identifiers from local storage in one flat array
 *
 * @returns {Array}
 */
function hasItem(id) {
    var orders = get();

    for (var i in orders) {
        var order = orders[i];

        for (var j in order.items) {
            var item = order.items[j];

            if (item.id == id) {
                return true;
            }

            // Check descendants
            for (var k in item.descendants) {
                if (item.descendants[k].id == id) {
                    return true;
                }
            }
        }
    }

    return false;
}

module.exports = {
    'addItem': addItem,
    'clear': clear,
    'get': get,
    'hasItem': hasItem,
    'removeItem': removeItem,
    'removeOrder': removeOrder,
};
